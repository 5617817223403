.container {
  position:relative;
  width: 100vw;
  height: 100vh;
  text-align: center;
  background-image: url('assets/bg_img_opt.jpg');
  /* background-image: url('https://upload.wikimedia.org/wikipedia/commons/f/f4/The_Scream.jpg'); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  font-family: fantasy;
  color: white;


}

.header {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 5px solid yellow; */
}

.content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex:1;
  width: 100%;
  height: 100%;
  /* border: 5px solid blue; */
}

@-webkit-keyframes PULSE {
  0%   { opacity: 0; height:0; width: 0;}
  50% { opacity: 1; height: 100%; width:100%;}
  100% { opacity: 0; height: 0; width: 0;}
}
@-moz-keyframes PULSE {
  0%   { opacity: 0; height:0; width: 0;}
  50% { opacity: 1; height: 100%; width:100%;}
  100% { opacity: 0; height: 0; width: 0;}
}
@-o-keyframes PULSE {
  0%   { opacity: 0; height:0; width: 0;}
  50% { opacity: 1; height: 100%; width:100%;}
  100% { opacity: 0; height: 0; width: 0;}
}
@keyframes PULSE {
  0%   { opacity: 0; height:0; width: 0;}
  50% { opacity: 1; height: 100%; width:100%;}
  100% { opacity: 0; height: 0; width: 0;}
}
.clickableArea {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding-top: 4vh;
  font-size: 8vw;
  font-family: fantasy;
  position: absolute;
  top: 44vh;
  height: 51vw;
  width: 43vw;
  /* border: 3px solid green; */
  border-radius:50%;
  background-color: #f37f7f14;
  filter: drop-shadow(2px 4px 6px black);
  box-shadow: 0px 1px 20px 20px #f37f7f14;
  
  -webkit-animation: PULSE 3s infinite; /* Safari 4+ */
  -moz-animation:    PULSE 3s infinite; /* Fx 5+ */
  -o-animation:      PULSE 3s infinite; /* Opera 12+ */
  animation:         PULSE 3s infinite; /* IE 10+, Fx 29+ */

  
}

.footer {
  position: absolute;
  bottom:0;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  padding: 10px;
  /* border: 3px solid lime; */
}

.footer a {
  padding-left: 10px;
  color:white;
  text-decoration: none;
}
a:hover {
  font-weight: bold;
}

/* .App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
